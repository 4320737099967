import { useMutation, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  GET_USERS_SAVED_ROUTE_PLANS,
  MutationVariables,
  REMOVE_SAVED_ROUTE_PLAN_TO_USER,
  SAVE_ROUTE_PLAN_TO_USER,
  SuccessResponse,
  Variables,
} from './query';

export const useSavedRoutePlans = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const { offline } = useOfflineContext();
  const fetchPolicy: WatchQueryFetchPolicy = offline ? 'cache-only' : 'cache-and-network';

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_ROUTE_PLANS, {variables: {userId}, skip: !userId, fetchPolicy},
  );

  const [saveRoutePlanToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_ROUTE_PLAN_TO_USER, {
      update(cache, { data }) {
        if (data) {
          cache.writeQuery<SuccessResponse>({ query: GET_USERS_SAVED_ROUTE_PLANS, variables: {userId}, data});
        }
      },
    });

  const [removeSavedRoutePlanFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVE_SAVED_ROUTE_PLAN_TO_USER, {
      update(cache, { data }) {
        if (data) {
          cache.writeQuery<SuccessResponse>({ query: GET_USERS_SAVED_ROUTE_PLANS, variables: {userId}, data});
        }
      },
    });

  return {response, saveRoutePlanToUser, removeSavedRoutePlanFromUser};
};
