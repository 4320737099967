/* eslint-disable max-len */
/* tslint:disable:max-line-length */
import React from 'react';
import styled from 'styled-components/macro';
import {
  BasicIconInText,
  borderRadius,
  PatreonButton,
  GhostButton,
} from '../../../styling/styleUtils';
import { mediumSize, mobileSize } from '../../../Utils';
import Modal from '../Modal';
import HikersHiking from '../../../assets/images/empty-states/patreon.svg';
import usePatreonAuthLink from '../../../hooks/patreon/usePatreonAuthLink';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';

const Root = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "img content"
    "patreon patreon";

  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "img"
      "content"
      "patreon";
  }
`;

const Image = styled.img`
  width: 11.25rem;
  height: 11.25rem;
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 1000px;
  float: right;

  @media (max-width: ${mediumSize}px) {
    width: 8rem;
    height: 8rem;
    margin-bottom: 2rem;
  }

  @media (max-width: ${mobileSize}px) {
    display: none;
  }
`;

const CloseButton = styled(GhostButton)`
  display: block;
  padding: 0.35rem 0.5rem;
  text-align: right;
  display: flex;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
`;

const ContentContainer = styled.div`
  grid-area: content;
  padding: 2rem;
  display: flex;

  @media (max-width: ${mobileSize}px) {
    padding: 1rem;
  }
`;

const Title = styled.h2`
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0;
  font-weight: 600;
`;

const Text = styled.p`
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
  font-size: 0.875rem;
  line-height: 1.7;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${mobileSize}px) {
    font-size: 1rem;
  }
`;

const ButtonContainer = styled.div`
  grid-area: patreon;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem 2rem;
  gap: 1rem;
`;

interface Props {
  title?: string;
  onCancel: () => void;
}

const JoinPatreonModal = (props: Props) => {
  const { onCancel } = props;
  const patreonAuthLink = usePatreonAuthLink();

  const title = props.title ?? 'Get exclusive features and support Wilderlist on Patreon!';

  return (
    <Modal
        onClose={onCancel}
        width={'600px'}
        height={'auto'}
        actions={null}
        contentStyles={{padding: 0, borderRadius, overflow: 'hidden'}}
      >
        <Root>
          <ContentContainer>
            <div>
              <Title>{title}</Title>
              <Text>
                <span>
                  If Wilderlist has added value to your adventures, please consider supporting us on Patreon. With no corporate funding or ads, everything is self-funded, and new features are built during late nights and weekends. Your donation helps cover operational costs, keep the app accesible for everyone, and bring exciting new features to life. Together, we can continue building something special for the hiking community. Thank you!
                </span>
                <Image src={HikersHiking} />
              </Text>
            </div>
          </ContentContainer>
          <ButtonContainer>
            <PatreonButton href={'https://www.patreon.com/Wilderlist'} target='_blank'>
              <BasicIconInText icon={faPatreon} />
              Become a patron
            </PatreonButton>
            <div>
              <small>
                Already a patron?{' '}
                <a href={patreonAuthLink}>Connect your Patreon account and access your member benefits.</a>
              </small>
            </div>
          </ButtonContainer>
          <CloseButton onClick={onCancel}>×</CloseButton>
        </Root>
      </Modal>
  );

};

export default JoinPatreonModal;
