const {point, featureCollection} = require('@turf/helpers');
const getCenter = require('@turf/center').default;
import {
  faCalendarAlt,
  faChartArea,
  faChartLine,
  faChevronRight,
  faClock,
  faCopy,
  faLock,
  faMapSigns,
  faPencilAlt,
  faRoad,
  faShareAlt,
  faShoePrints,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import upperFirst from 'lodash/upperFirst';
import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components/macro';
import {OriginLocation} from '../../../hooks/directions/useDirectionsOrigin';
import useFluent from '../../../hooks/useFluent';
import useMapContext from '../../../hooks/useMapContext';
import fetchRouteDetails from '../../../queries/routePlans/useRoutePlanDetails/fetch';
import { addTripReportLink, routePlanLink } from '../../../routing/Utils';
import {
  baseColor,
  BasicIconInText,
  IconContainer,
  lightBaseColor,
  lightBorderColor,
  LinkButton,
  primaryColor,
  SemiBold,
  Seperator,
} from '../../../styling/styleUtils';
import {CampsiteOwnership, Coordinate, RoutePlan, TrailType} from '../../../types/graphQLTypes';
import {AggregateItem, AuxiliaryItem, CoreItem, isCoreItem, UserContentItem} from '../../../types/itemTypes';
import { getTrailAccessNice } from '../../../utilities/trailUtils';
import StarListButton from '../../peakLists/detail/StarListButton';
import SimpleShare from '../detailComponents/header/sharing/SimpleShare';
import StarButtonWrapper from '../detailComponents/header/starButton';
import {mountainNeutralSvg, tentNeutralSvg, trailDefaultSvg} from '../svgIcons';
import CrowFliesDistance from './CrowFliesDistance';
import LatestTrip from './LatestTrip';
import SimplePercentBar from './SimplePercentBar';
import { ReturnedRoutePlan } from '../../../queries/routePlans/useRoutePlanDetails/query';

const InlineCard = styled.div`
  margin: 0 -1rem;
  padding: 1rem;
  border-top: solid 1px ${lightBorderColor};

  &:last-of-type {
    border-bottom: solid 1px ${lightBorderColor};
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 5.625rem;
  grid-column-gap: 0.35rem;
  margin-bottom: 0.25rem;
  margin-right: -1rem;
`;

const IconHeader = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.15rem;
`;

const SavedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const FlexRow = styled.div`
  display: flex;
  font-size: 0.875rem;
  color: ${lightBaseColor};
`;

const MidFlexRow = styled(FlexRow)`
  margin-bottom: 0.5rem;
`;

const PullRight = styled(FlexRow)`
  margin-left: auto;
`;

const ListItem = styled(FlexRow)`
  margin-right: 1rem;
`;

const IconDetailGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1rem 1fr 1rem 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  font-size: 0.75rem;
  color: ${lightBaseColor};
`;

const UserNote = styled(ListItem)`
  margin-top: 1rem;
  font-size: 0.875rem;
  font-style: italic;
  display: flex;
  color: ${baseColor};
`;

interface BaseProps {
  id: string;
  title: string;
  url: string;
  locationText: string | null;
  type: CoreItem | AggregateItem | AuxiliaryItem | UserContentItem;
  customIcon: boolean;
  icon: string | any;
  note?: string | null;
}

export type TypeProps = BaseProps & (
  {
    type: CoreItem.mountain,
    elevation: number;
    location: Coordinate;
    distanceToCenter: number;
    trailAccessible?: boolean;
    roadAccessible?: boolean;
  } | {
    type: CoreItem.trail,
    formattedType: string,
    trailLength: number;
    slopeText: string | null;
    distanceToCenter: number;
    location: Coordinate;
    line: Coordinate[];
  } | {
    type: CoreItem.campsite,
    formattedType: string;
    ownership: CampsiteOwnership | null;
    distanceToCenter: number;
    location: Coordinate;
    carCamping?: boolean;
  } | {
    type: AggregateItem.list
    numMountains: number;
    numTrails: number;
    numCampsites: number;
    percent: number | null;
    bbox: [number, number, number, number] | null;
  } | {
    type: AggregateItem.parentTrail
    numTrails: number;
  } | {
    type: AuxiliaryItem.trailhead
    totalTrailMiles: number;
    location: Coordinate;
  } | {
    type: AuxiliaryItem.viewpoint
    elevation: number;
    location: Coordinate;
    trailAccessible?: boolean;
    roadAccessible?: boolean;
  } | {
    type: AuxiliaryItem.waterpoint
    formattedType: string;
    location: Coordinate;
  } | {
    type: AuxiliaryItem.park
    formattedType: string;
    numMountains: number;
    trailMileage: number;
    numCampsites: number;
    location: Coordinate;
    bbox: [number, number, number, number] | null;
  } | {
    type: UserContentItem.route
    location: Coordinate;
    authors: string;
    lastModified: Date;
    totalLength: number;
    numberOfSegments: number;
    visibility: RoutePlan['visibility'];
    routePlan: ReturnedRoutePlan;
  }
);

type Props = TypeProps & {
  mapCenter?: Coordinate;
  usersLocation?: undefined | null | OriginLocation;
  changeUsersLocation?: () => void;
};

const ResultItem = (props: Props) => {
  const {
    id, title, customIcon, icon, url, type,
    mapCenter, usersLocation, changeUsersLocation,
  } = props;
  const history = useHistory();
  const getString = useFluent();
  const mapContext = useMapContext();
  const onMouseLeave = () => {
    if (mapContext.intialized) {
      mapContext.clearExternalHoveredPopup();
    }
  };
  const onMouseEnter = () => {
    if (mapContext.intialized) {
      if (props.type === AggregateItem.list && props.bbox) {
        const center = getCenter(featureCollection([
          point(props.bbox.slice(0, 2)),
          point(props.bbox.slice(2, 4)),
        ]));
        mapContext.setExternalHoveredPopup(
          props.title,
          AggregateItem.list,
          '',
          [center.geometry.coordinates[0], props.bbox[3]],
          undefined,
          props.bbox,
        );
      } else if (props.type !== AggregateItem.list && props.type !== AggregateItem.parentTrail) {
        let subtitle: string = '';
        if (props.type === CoreItem.mountain) {
          if (props.elevation) {
            subtitle = props.elevation + 'ft';
          }
        } else if (props.type === CoreItem.trail) {
          if (props.formattedType && props.trailLength) {
            const trailLength = props.trailLength;
            const trailLengthDisplay = trailLength < 0.1
              ? Math.round(trailLength * 5280) + ' ft'
              : parseFloat(trailLength.toFixed(1)) + ' mi';
            subtitle = !isNaN(trailLength)
              ? trailLengthDisplay + ' long ' + getString('global-formatted-trail-type', {type})
              : getString('global-formatted-trail-type', {type});
          }
        } else if (props.type === CoreItem.campsite) {
          if (props.formattedType) {
            subtitle = props.formattedType;
          }
        }
        const line = props.type === CoreItem.trail && props.line ? props.line : undefined;
        mapContext.setExternalHoveredPopup(props.title, props.type, subtitle, props.location, line);
      }
    }
  };
  useEffect(() => {
    return () => {
      if (mapContext.intialized) {
        mapContext.clearExternalHoveredPopup();
      }
    };
  }, [mapContext]);
  const iconEl = customIcon ? (
    <IconContainer
      $color={primaryColor}
      dangerouslySetInnerHTML={{__html: icon}}
    />
  ) : (
    <IconContainer $color={primaryColor}>
      <BasicIconInText icon={icon} />
    </IconContainer>
  );

  const star = type === AggregateItem.list ? (
    <StarListButton
      peakListId={id}
      compact={true}
    />
  ) : type !== UserContentItem.route ? (
    <StarButtonWrapper
      id={id}
      type={type}
      compact={true}
    />
  ) : null;

  let content: React.ReactElement<any> | null;
  let utilities: React.ReactElement<any> | null = null;
  if (props.type === AggregateItem.list) {
    const numMountains = props.numMountains ? (
      <ListItem>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: mountainNeutralSvg}}
        />
        {props.numMountains} {props.numMountains > 1
          ? getString('global-text-value-mountains') : getString('global-text-value-mountain')}
      </ListItem>
    ) : null;
    const numTrails = props.numTrails ? (
      <ListItem>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
        />
        {props.numTrails} {props.numTrails > 1
          ? getString('global-text-value-trails') : getString('global-text-value-trail')}
      </ListItem>
    ) : null;
    const numCampsites = props.numCampsites ? (
      <ListItem>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: tentNeutralSvg}}
        />
        {props.numCampsites} {props.numCampsites > 1
          ? getString('global-text-value-campsites') : getString('global-text-value-campsite')}
      </ListItem>
    ) : null;

    const locationText = props.locationText ? (
      <FlexRow>
        {upperFirst(props.locationText)}
      </FlexRow>
    ) : null;

    const percentBar = props.percent !== null ? (
      <PullRight>
        <SimplePercentBar
          percent={props.percent}
        />
      </PullRight>
    ) : null;

    content = (
      <>
        <MidFlexRow>
          {numMountains}
          {numTrails}
          {numCampsites}
        </MidFlexRow>
        <FlexRow>
          {locationText}
          {percentBar}
        </FlexRow>
      </>
    );
  } else if (props.type === AggregateItem.parentTrail) {
    const numTrails = props.numTrails ? (
      <ListItem>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
        />
        {props.numTrails} {props.numTrails > 1
          ? getString('global-text-value-trails') : getString('global-text-value-trail')}
      </ListItem>
    ) : null;

    const _in = props.locationText && props.locationText.toLowerCase().includes('across') ? ' ' : ' in ';
    const locationText = props.locationText ? (
      <FlexRow>
        {upperFirst(getString('global-formatted-trail-type', {type: TrailType.parentTrail}))}
        {_in}
        {upperFirst(props.locationText)}
      </FlexRow>
    ) : null;

    content = (
      <>
        <MidFlexRow>
          {locationText}
        </MidFlexRow>
        <FlexRow>
          {numTrails}
        </FlexRow>
      </>
    );
  } else if (props.type === CoreItem.mountain || props.type === AuxiliaryItem.viewpoint) {
    const trailAccessible = props.trailAccessible ? (
      <MidFlexRow>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
        />
        {getString('mountain-detail-trail-accessible')}
      </MidFlexRow>
    ) : null;
    const roadAccessible = props.roadAccessible ? (
      <MidFlexRow>
        <BasicIconInText icon={faRoad} />
        {getString('mountain-detail-road-accessible')}
      </MidFlexRow>
    ) : null;
    const locationText = props.locationText ? (
      <FlexRow>
        {upperFirst(props.locationText)}
      </FlexRow>
    ) : null;
    content = (
      <>
        {trailAccessible}
        {roadAccessible}
        <MidFlexRow>
          <BasicIconInText icon={faChartArea} />
          {props.elevation}ft
          <Seperator>|</Seperator>
          {locationText}
        </MidFlexRow>
      </>
    );
  } else if (props.type === CoreItem.campsite) {

    const carAccessible = props.carCamping ? (
      <MidFlexRow>
        <BasicIconInText icon={faRoad} />
        {getString('campsite-detail-car-camping')}
      </MidFlexRow>
    ) : null;
    const backcountry = props.carCamping === false ? (
      <MidFlexRow>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
        />
        {getString('campsite-detail-backcountry')}
      </MidFlexRow>
    ) : null;
    content = (
      <>
        {carAccessible}
        {backcountry}
        <MidFlexRow>
          {getString('campsite-detail-subtitle', {
            ownership: '' + props.ownership,
            type: props.formattedType,
            location: props.locationText,
          })}
        </MidFlexRow>
      </>
    );
  } else if (props.type === AuxiliaryItem.waterpoint) {
    content = (
      <MidFlexRow>
        {props.formattedType} in {props.locationText}
      </MidFlexRow>
    );
  } else if (props.type === AuxiliaryItem.trailhead) {
    content = (
      <MidFlexRow>
        <ListItem>
        <IconContainer
            $color={lightBaseColor}
            dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
          />
          {getTrailAccessNice(props.totalTrailMiles)}
        </ListItem>
      </MidFlexRow>
    );
  } else if (props.type === CoreItem.trail) {
    const slopeText = props.slopeText ? (
      <PullRight>
        <BasicIconInText icon={faChartLine} />
        {props.slopeText}
      </PullRight>
    ) : null;
    const trailLength = props.trailLength < 0.1
      ? getString('distance-feet-formatted', {feet: Math.round(props.trailLength * 5280)})
      : getString('directions-driving-distance', {miles: parseFloat(props.trailLength.toFixed(1))});
    content = (
      <>
        <MidFlexRow>
          {getString('trail-detail-subtitle', {
            type: props.formattedType,
            segment: 0,
            state: '' + props.locationText,
          })}
        </MidFlexRow>
        <MidFlexRow>
          <FlexRow>
            <BasicIconInText icon={faShoePrints} />
            {trailLength}
          </FlexRow>
          {slopeText}
        </MidFlexRow>
      </>
    );
  } else if (props.type === UserContentItem.route) {
    const totalLength = props.totalLength < 0.1
      ? getString('distance-feet-formatted', {feet: Math.round(props.totalLength * 5280)})
      : getString('directions-driving-distance', {miles: parseFloat(props.totalLength.toFixed(1))});

    const onLogTrip = () => {
      fetchRouteDetails(props.routePlan.id).then(d => {
        if (d?.data.routePlan?.segments) {
          history.push({
            pathname: addTripReportLink({}),
            state: { segments: d.data.routePlan.segments },
          });
        }
      });
    };
    const onCopyRoute = () => {
      fetchRouteDetails(props.routePlan.id).then(d => {
        if (d?.data.routePlan?.segments) {
          history.push({
            pathname: routePlanLink(),
            state: { routePlan: {...d.data.routePlan, name: 'Copy of ' + props.routePlan.name} },
          });
        }
      });
    };
    content = (
      <IconDetailGrid>
        <BasicIconInText icon={faUser} />
        <div>{props.authors}</div>
        <BasicIconInText icon={faShoePrints} />
        <div>{getString('global-text-value-total-route-length')}: {totalLength}</div>
        <BasicIconInText icon={faClock} />
        <div>{getString('global-text-value-last-edit')}: {
          props.lastModified.toLocaleDateString('en-US')
        } {
          props.lastModified.toLocaleTimeString('en-US', {hour12: true, hour: 'numeric', minute: '2-digit'})
        }</div>
        <BasicIconInText icon={faMapSigns} />
        <div>{props.numberOfSegments} segment{props.numberOfSegments !== 1 && 's'}</div>
        <BasicIconInText icon={faLock} />
        <div>{getString('global-text-value-visibility')}: {upperFirst(props.visibility)}</div>
      </IconDetailGrid>
    );
    utilities = (
      <FlexRow>
        <Link to={url + '&edit=yes'} title={'Edit'}>
          <BasicIconInText icon={faPencilAlt} />
        </Link>
        <LinkButton
          onClick={onCopyRoute}
        >
          <BasicIconInText icon={faCopy} title={'Copy'} />
        </LinkButton>
        <LinkButton
          onClick={onLogTrip}
          title={'Log trip'}>
          <BasicIconInText icon={faCalendarAlt} />
        </LinkButton>
        <SimpleShare
          pageName={title}
          type={AuxiliaryItem.park}
          url={process.env.REACT_APP_DOMAIN_NAME + url}
        >
          <BasicIconInText icon={faShareAlt} />
        </SimpleShare>
      </FlexRow>
    );
  } else if (props.type === AuxiliaryItem.park) {
    const numMountains = props.numMountains ? (
      <ListItem>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: mountainNeutralSvg}}
        />
        {props.numMountains} {props.numMountains > 1
          ? getString('global-text-value-mountains') : getString('global-text-value-mountain')}
      </ListItem>
    ) : null;
    const trailMileage = props.trailMileage ? (
      <ListItem>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
        />
        {getTrailAccessNice(props.trailMileage)}
      </ListItem>
    ) : null;
    const numCampsites = props.numCampsites ? (
      <ListItem>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: tentNeutralSvg}}
        />
        {props.numCampsites} {props.numCampsites > 1
          ? getString('global-text-value-campsites') : getString('global-text-value-campsite')}
      </ListItem>
    ) : null;

    content = (
      <>
      <MidFlexRow>
        {props.formattedType} in {props.locationText}
      </MidFlexRow>
      <MidFlexRow>
        {trailMileage}
      </MidFlexRow>
      {(numMountains || numCampsites) && (
        <MidFlexRow>
          {numMountains}
          {numCampsites}
        </MidFlexRow>
      )}
      </>
    );
  } else {
    content = null;
  }

  const latestTrip = isCoreItem(props.type) ? (
    <PullRight>
      <LatestTrip
        item={props.type}
        id={id}
      />
    </PullRight>
  ) : null;

  const distanceAndLastHiked = 'location' in props && mapCenter && changeUsersLocation ? (
    <FlexRow>
      <CrowFliesDistance
        location={props.location}
        usersLocation={usersLocation}
        mapCenter={mapCenter}
        changeUsersLocation={changeUsersLocation}
      />
      {latestTrip}
    </FlexRow>
  ) : null;

  const userNote = props.note ? (
    <UserNote>
      <BasicIconInText icon={faChevronRight} />
      {props.note}
    </UserNote>
  ) : null;

  return (
    <InlineCard
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <Header>
        <div>
          <IconHeader>
            <Link to={url}>
              {iconEl}
            </Link>
            <Link to={url}>
              <SemiBold>{title}</SemiBold>
            </Link>
          </IconHeader>
        </div>
        <div>
          <SavedContainer>
            {star}
            {utilities}
          </SavedContainer>
        </div>
      </Header>
      {content}
      {distanceAndLastHiked}
      {userNote}
    </InlineCard>
  );
};

export default ResultItem;
