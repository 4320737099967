import { gql } from '@apollo/client';
import {
  User,
} from '../../../types/graphQLTypes';
import { ReturnedRoutePlan, routePlanQuery } from '../useRoutePlanDetails/query';

export const ROUTE_PLANS_PER_PAGE = 10;

export const GET_USERS_SAVED_ROUTE_PLANS = gql`
  query GetSavedRoutePlans($userId: ID) {
    user(id: $userId) {
      id
      savedRoutePlans {
        ${routePlanQuery}
      }
    }
  }
`;

export const SAVE_ROUTE_PLAN_TO_USER = gql`
  mutation SaveRoutePlanToUser($userId: ID!, $routePlanId: ID!) {
    user: saveRoutePlanToUser(userId: $userId, routePlanId: $routePlanId) {
      id
      savedRoutePlans {
        ${routePlanQuery}
      }
    }
  }
`;

export const REMOVE_SAVED_ROUTE_PLAN_TO_USER = gql`
  mutation RemoveSavedRoutePlanFromUser($userId: ID!, $routePlanId: ID!) {
    user: removeSavedRoutePlanFromUser(userId: $userId, routePlanId: $routePlanId) {
      id
      savedRoutePlans {
        ${routePlanQuery}
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  routePlanId: string;
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    savedRoutePlans: (null | ReturnedRoutePlan)[];
  };
}