import React, { PropsWithChildren, useState } from 'react';
import JoinPatreonModal from './JoinPatreonModal';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  patreonPrimaryColor } from '../../../styling/styleUtils';

const Trigger = styled.span`
  display: flex;
  align-items: center;
  position: relative;
`;

export const LockIcon = styled(FontAwesomeIcon)`
  color: ${patreonPrimaryColor};
  font-size: 0.75rem;
  margin-right: 0.25rem;
`;

export default function PatreonTrigger({
  children,
  modalText,
}: PropsWithChildren<{modalText?: string}>) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <>
    <Trigger onClick={() => setModalOpen(true)}>
        {children}
    </Trigger>
      {modalOpen && (
        <JoinPatreonModal
          onCancel={() => setModalOpen(false)}
          title={modalText}
        />
      )}
    </>
  );
}