import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import localforage from 'localforage';
import noop from 'lodash/noop';
import { useEffect } from 'react';
import { syncOfflineTiles } from '../../components/offline/offlineMaps/offlineMapsSync';
import { MAP_VERSION, OFFLINE_MAPS_CACHE_NAME, SAVED_MAPS_KEY, SavedOfflineMap } from '../../components/offline/offlineMaps/Utils';
import {
  GET_ROUTE_PLAN_DETAIL,
  SuccessResponse as RoutePlanDetailSuccessResponse,
  Variables as RoutePlanDetailVariables,
} from '../../queries/routePlans/useRoutePlanDetails/query';
import {
  GET_USERS_SAVED_ROUTE_PLANS,
  ROUTE_PLANS_PER_PAGE,
  SuccessResponse,
  Variables,
} from '../../queries/routePlans/useSavedRoutePlans/query';
import fetchElevationLines from '../servicesHooks/elevation/fetchElevationLines';
import useCurrentUser from '../useCurrentUser';

const offlineWorker = new Worker(process.env.PUBLIC_URL + '/offline-worker.js');

const useOfflineSync = (
  client: ApolloClient<NormalizedCacheObject> | null | undefined,
  persistor: CachePersistor<NormalizedCacheObject> | null | undefined,
) => {
  const currentUser = useCurrentUser();
  useEffect(() => {
    offlineWorker.postMessage({
      message: 'SYNC_OFFLINE_DATA',
      userId: currentUser?._id,
    });
  }, [currentUser]);

  useEffect(() => {
    const onWorkerMessage = async (event: MessageEvent<{message: string}>) => {
      if (event.data.message === 'OFFLINE_DATA_SYNC_COMPLETE' && persistor && client) {
        await persistor.restore().catch(console.error);
        const response = client.cache.readQuery<SuccessResponse, Variables>({
          query: GET_USERS_SAVED_ROUTE_PLANS,
          variables: {userId: currentUser?._id ?? null},
        });
        const latestRoutePlans = [...(response?.user?.savedRoutePlans ?? [])].sort((a, b) => {
        if (a && b) {
          const dateA = a.modified ? a.modified : a.created;
          const dateB = b.modified ? b.modified : b.created;
          return Number(dateB) - Number(dateA);
          } else {
            return -1;
          }
        }).slice(0, ROUTE_PLANS_PER_PAGE);
        latestRoutePlans.forEach(route => {
          if (route) {
            const cachedRouteDetail = client.readQuery<RoutePlanDetailSuccessResponse, RoutePlanDetailVariables>({
              query: GET_ROUTE_PLAN_DETAIL,
              variables: {id: route.id},
            });
            if (cachedRouteDetail?.routePlan?.segments) {
              cachedRouteDetail.routePlan.segments.forEach(segment => {
                fetchElevationLines(
                  {
                    lineId: `elevation-profile-segmenet-${segment.id}`,
                    line: segment.lines.flat(),
                    includeIncline: true,
                    includeMinMax: true,
                  },
                  noop,
                );
              });
            }
          }
        });
      }
    };
    offlineWorker.addEventListener('message', onWorkerMessage);
    return () => {
      offlineWorker.removeEventListener('message', onWorkerMessage);
    };
  }, [client, persistor, currentUser]);

  useEffect(() => {
    if (currentUser && navigator && navigator.serviceWorker) {
      setTimeout(() => {
        const today = new Date().getTime();
        localforage.getItem<SavedOfflineMap[]>(SAVED_MAPS_KEY)
          .then(maps => {
            if (maps?.some(m => {
                if (!m.lastSync || m.mapVersion !== MAP_VERSION) {
                  return true;
                }
                const expiresOn = new Date(m.lastSync).getTime() + (30 * 24 * 60 * 60 * 1000);
                return today > expiresOn;
            })) {
              syncOfflineTiles({resetAll: true});
            } else if (maps && maps.length) {
              caches.open(OFFLINE_MAPS_CACHE_NAME)
                .then(cache => cache.keys())
                .then(keys => !keys.length ? syncOfflineTiles() : null);
            }
          });
      }, 3000);
    }
  }, [currentUser]);
};

export default useOfflineSync;
