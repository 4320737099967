/* eslint-disable max-len */
/* tslint:disable:max-line-length */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../Modal';
import { BasicIconInText, borderRadius, PatreonButton, GhostButton, mediumBaseColor } from '../../../styling/styleUtils';
import styled from 'styled-components';
import { mediumSize, mobileSize } from '../../../Utils';
import ProfilePicture from '../../../assets/images/profile-picture.png';
import usePatreonAuthLink from '../../../hooks/patreon/usePatreonAuthLink';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Types } from 'mongoose';

const Root = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "img content"
    "patreon patreon";

  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "img"
      "content"
      "patreon";
  }
`;

const Image = styled.img`
  width: 11.25rem;
  height: 11.25rem;
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 1000px;
  float: right;

  @media (max-width: ${mediumSize}px) {
    width: 8rem;
    height: 8rem;
    margin-bottom: 2rem;
  }

  @media (max-width: ${mobileSize}px) {
    display: none;
  }
`;

const CloseButton = styled(GhostButton)`
  display: block;
  padding: 0.35rem 0.5rem;
  text-align: right;
  display: flex;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
  transition: all 0.2s ease;
  opacity: 0;
`;

const ContentContainer = styled.div`
  grid-area: content;
  padding: 2rem;
  display: flex;

  @media (max-width: ${mobileSize}px) {
    padding: 1rem;
  }
`;

const Text = styled.p`
  display: block;
  margin-top: 0.7rem;
  font-size: 0.875rem;
  line-height: 1.7;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${mobileSize}px) {
    font-size: 1rem;
  }
`;

const ButtonContainer = styled.div`
  grid-area: patreon;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem 2rem;
  gap: 1rem;
`;

const Signature = styled(Text)`
  text-align: right;
  color: ${mediumBaseColor};
  font-style: italic;
`;

const LAST_DISMISSED_LOCAL_STORAGE_KEY = 'patreon_modal_last_dismissed_on';

export default function PatreonIntermittentModal() {
  const user = useCurrentUser();
  const patreonAuthLink = usePatreonAuthLink();
  const [open, setOpen] = useState<boolean>(false);
  const [allowClose, setAllowClose] = useState(false);
  const [lastDismissed, setLastDismissed] = useState<string | null>(localStorage.getItem(LAST_DISMISSED_LOCAL_STORAGE_KEY));

  const onClose = useCallback(() => {
    if (allowClose) {
      setOpen(false);
      setLastDismissed(String(Date.now()));
    }
  }, [allowClose]);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 500);
  }, [setAllowClose]);

  useEffect(() => {
    setTimeout(() => {
      setAllowClose(true);
    }, 2500);
  }, [setAllowClose]);

  useEffect(() => {
    if (lastDismissed) {
      localStorage.setItem(LAST_DISMISSED_LOCAL_STORAGE_KEY, lastDismissed);
    }
  }, [lastDismissed]);

  const shouldShowModal = useMemo(() => {
    if (!user || user.membershipLevel) {
      return false;
    }
    const lastDismissedDate = lastDismissed ? new Date(Number(lastDismissed)) : null;
    const userCreatedDate = new Types.ObjectId(user._id as any).getTimestamp() as Date;
    const relevantDate = lastDismissedDate || userCreatedDate;

    const today = new Date();

    today.setHours(0, 0, 0, 0);
    relevantDate.setHours(0, 0, 0, 0);

    const isLessThan21Days =
      Math.abs(today.getTime() - relevantDate.getTime()) / (1000 * 60 * 60 * 24) < 21;

    if (isLessThan21Days) {
      return false;
    }

    return true;
  }, [user, lastDismissed]);

  if (!shouldShowModal) {
    return null;
  }

  return (
    <>
    {open && (
        <Modal
          onClose={null}
          width={'600px'}
          height={'auto'}
          actions={null}
          contentStyles={{padding: 0, borderRadius, overflow: 'hidden'}}
      >
        <Root>
          <ContentContainer>
            <div>
              <Image src={ProfilePicture} />
              <Text>
                In 2018, after hiking the Pacific Crest Trail, I founded Wilderlist to create the tools I felt were missing from the hiking community. Since then, the community has grown beyond anything I imagined, and Wilderlist has become a true labor of love.
              </Text>
              <Text>
                However, as our user base expands, so do the costs and time commitments. With no corporate funding or ads, everything is self-funded, and new features are built during late nights and weekends.
              </Text>
              <Text>
                If Wilderlist has added value to your adventures, please consider supporting us on Patreon. Your donation helps cover operational costs, keep the app accesible for everyone, and bring exciting new features to life. Together, we can continue building something special for the hiking community. Thank you!
              </Text>
              <Signature>
                - Kyle S.
              </Signature>
            </div>
          </ContentContainer>
          <ButtonContainer>
            <PatreonButton href={'https://www.patreon.com/Wilderlist'} target='_blank'>
              <BasicIconInText icon={faPatreon} />
              Become a patron
            </PatreonButton>
            <div>
              <small>
                Already a patron?{' '}
                <a href={patreonAuthLink}>Connect your Patreon account and access your member benefits.</a>
              </small>
            </div>
          </ButtonContainer>
          <CloseButton style={{opacity: allowClose ? 1 : 0}} onClick={onClose}>×</CloseButton>
        </Root>
      </Modal>
      )}
    </>
  );
}