import React from 'react';
import styled from 'styled-components';
import { baseBoxShadow, patreonPrimaryColor, ShadowButton } from '../../../styling/styleUtils';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Root = styled(ShadowButton)<{$compact: boolean}>`
  position: relative;
  ${({$compact}) => $compact ? 'padding: 0.1rem 1rem 0.1rem 0.5rem;' : 'padding-right: 1rem;'}
`;
const LockIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(15%, 40%);
  border-radius: 1000px;
  background-color: ${patreonPrimaryColor};
  font-size: 0.55rem;
  width: 1.1rem;
  height: 1.1rem;
  color: white;
  box-shadow: ${baseBoxShadow};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function PatreonLockedButton({
  children,
  compact,
  onClick,
}: React.PropsWithChildren<{
  compact?: boolean
  onClick?: () => void,
}>) {
  return (
    <Root $compact={!!compact} onClick={onClick}>
      {children}
      <LockIcon>
        <FontAwesomeIcon icon={faLock} />
      </LockIcon>
    </Root>
  );
}