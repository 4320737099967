import { faWifi } from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components/macro';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useWindowWidth from '../../../hooks/useWindowWidth';
import {Routes} from '../../../routing/routes';
import { ContentHeader } from '../../../styling/Grid';
import {
  BasicIconInText,
  lightBaseColor,
  lightBorderColor,
  lowWarningColor,
  lowWarningColorLight,
  lowWarningColorTextOverlay,
} from '../../../styling/styleUtils';
import {mobileSize} from '../../../Utils';
import MobileMapViewBox from './MobileMapViewBox';
import Search from './search';

const Root = styled.div`
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  border: solid 1px ${lightBorderColor};
  border-radius: 8px;
  background-color: #fff;

  @media (max-width: ${mobileSize}px) {
    position: fixed;
    border: none;
  }
`;

const OfflineNotification = styled.div`
  position: absolute;
  top: 3rem;
  right: 0;
  left: 0;
  transform: translateY(calc(100% + 0.5rem));
  margin: auto;
  width: min-content;
  white-space: nowrap;
  padding: 0.25rem;
  background-color: ${lowWarningColorLight};
  border: solid 1px ${lowWarningColor};
  color: ${lowWarningColorTextOverlay};
  box-shadow: 0 1px 3px 1px #d1d1d1;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.875rem;
  pointer-events: none;

  strong {
    font-weight: 600;
  }

  @media (max-width: ${mobileSize}px) {
    transform: none;
    position: fixed;
    top: 0;
  }
`;

const WifiIcon = styled.div`
  color: ${lightBaseColor};
  position: relative;
  display: inline-block;
  opacity: 0.75;

  &:after {
    content: '';
    opacity: 1;
    width: 1.4rem;
    height: 2px;
    background-color: ${lightBaseColor};
    border-bottom: solid 1px ${lowWarningColorLight};
    position: absolute;
    left: -2px;
    top: -3px;
    bottom: 0;
    margin: auto;
    transform: rotate(50deg);
  }
`;

const Header = () => {
  const { offline } = useOfflineContext();
  const { pathname } = useLocation();
  const windowWidth = useWindowWidth();
  const user = useCurrentUser();

  let normalizedPathname: string;
  if (pathname.includes('saved')) {
    normalizedPathname = Routes.Saved;
  } else if  (pathname.includes('your-stats')) {
    normalizedPathname = Routes.YourTrips;
  } else if (pathname.includes('user') && !pathname.includes('settings') && !pathname.includes('list')) {
    if (pathname.includes('search')) {
      normalizedPathname = Routes.SearchUsers;
    } else {
      normalizedPathname = Routes.UserProfile;
    }
  } else if (pathname.includes('list')) {
    normalizedPathname = Routes.SearchLists;
  } else if (pathname.includes('mountain')) {
    normalizedPathname = Routes.SearchMountains;
  } else if (pathname.includes('campsite')) {
    normalizedPathname = Routes.SearchCampsites;
  } else if (pathname.includes('viewpoint')) {
    normalizedPathname = Routes.SearchViewpoints;
  } else if (pathname.includes('waterpoint')) {
    normalizedPathname = Routes.WaterpointDetail;
  } else if (pathname.includes('trail')) {
    normalizedPathname = Routes.SearchTrails;
  } else if (pathname.includes('summit-view')) {
    normalizedPathname = Routes.SummitView;
  } else if (
      pathname.includes(Routes.YourTrips) ||
      pathname.includes(Routes.YourProgress) ||
      pathname.includes(Routes.YourTotals)
    ) {
    normalizedPathname = Routes.YourTrips;
  } else if (pathname.includes('route')) {
    normalizedPathname = Routes.RoutePlan;
  } else if (pathname.includes('park')) {
    normalizedPathname = Routes.SearchParks;
  } else if (pathname.includes('/point/')) {
    normalizedPathname = Routes.GeoLocationDetail;
  } else if (pathname.includes('offline-maps')) {
    normalizedPathname = Routes.GeoLocationDetail;
  } else {
    normalizedPathname = pathname;
  }

  const showBackground = useMemo(
    () => (windowWidth > mobileSize && normalizedPathname !== Routes.Landing ) ||
                         (normalizedPathname !== Routes.Landing &&
                          normalizedPathname !== Routes.Saved &&
                          normalizedPathname !== Routes.UserProfile &&
                          normalizedPathname !== Routes.SearchLists  &&
                          normalizedPathname !== Routes.SearchTrails  &&
                          normalizedPathname !== Routes.SearchCampsites  &&
                          normalizedPathname !== Routes.SearchCampsites  &&
                          normalizedPathname !== Routes.SearchViewpoints  &&
                          normalizedPathname !== Routes.WaterpointDetail  &&
                          normalizedPathname !== Routes.SearchMountains &&
                          normalizedPathname !== Routes.SearchParks &&
                          normalizedPathname !== Routes.AddTripReport &&
                          normalizedPathname !== Routes.TripReportDetail &&
                          normalizedPathname !== Routes.YourTrips &&
                          normalizedPathname !== Routes.RoutePlan &&
                          normalizedPathname !== Routes.GeoLocationDetail &&
                          normalizedPathname !== Routes.OfflineMaps &&
                          normalizedPathname !== Routes.EditTripReport &&
                          normalizedPathname !== Routes.UploadTripReports),
   [windowWidth, normalizedPathname],
  );

  const showMobileMapViewBox = useMemo(
    () => windowWidth <= mobileSize && !showBackground && normalizedPathname !== Routes.Landing,
    [windowWidth, showBackground, normalizedPathname],
  );

  const mobileMapViewbox = showMobileMapViewBox ? <MobileMapViewBox key={pathname} /> : null;
  const searchBar = windowWidth > mobileSize ? (
    <Root style={{
      paddingLeft: showBackground ? '2.4rem' : undefined,
      borderBottomLeftRadius: showBackground ? 0 : undefined,
      borderBottomRightRadius: showBackground ? 0 : undefined,
      display: normalizedPathname === Routes.SummitView ? 'none' : undefined,
    }}>
      <Search />
    </Root>
  ) : null;

  useEffect(() => {
    if (!showMobileMapViewBox) {
      window.scrollTo(0, 0);
    }
  }, [showMobileMapViewBox, pathname]);

  const offlineNotification = offline && pathname === Routes.Landing ? (
    <OfflineNotification>
      <strong>
        <WifiIcon>
          <BasicIconInText icon={faWifi} />
        </WifiIcon>
        Offline
      </strong>
      <small>Functionality may be limited</small>
    </OfflineNotification>
  ) : null;

  return (
    <ContentHeader $user={Boolean(user)}>
      {searchBar}
      {offlineNotification}
      {mobileMapViewbox}
    </ContentHeader>
  );
};

export default Header;
