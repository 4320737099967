import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePatreonAuthLink(): string {
  const { pathname } = useLocation();

  return useMemo(() => `https://www.patreon.com/oauth2/authorize
?response_type=code
&scope=${encodeURIComponent('identity identity[email]')}
&client_id=${process.env.REACT_APP_PATREON_CLIENT_ID}
&redirect_uri=${process.env.REACT_APP_PATREON_REDIRECT_URI}
&state="${pathname}"`, [pathname]);
}